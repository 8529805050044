
.fullPagePadded {
  margin: 15px;
  margin-top: 10vh;
  text-align: center;
}

p {
  font-size: 20px;
}

.codeBlock {
  padding: 15px;
  font-family: monospace;
  background-color: #303030;
  color: #ffffff;
  border-radius: 15px;
}
.codeComment {
  color: #638c66;
  font-style: italic;
}